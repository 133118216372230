import WesternAustralia from "../western-australia.png";
import PedalMafia from "./pedal-mafia.png";
import ARA from "./ara.png";
import Perth from "./perth.png";
import Hahn from "./hahn.png";
const Logos = {
    pedalMafia: {
        src: PedalMafia,
        title: "Pedal Mafia",
        tickerOnly: false,
    },
    ara: {
        src: ARA,
        title: "ARA Group",
        tickerOnly: false,
    },
    perth: {
        src: Perth,
        title: "Perth",
        tickerOnly: false,
    },
    hahn: {
        src: Hahn,
        title: "Hahn",
        tickerOnly: false,
    },
    wa: {
        src: WesternAustralia,
        title: "Western Australia",
        tickerOnly: true,
    },
};
export default Logos;
